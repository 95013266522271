<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="3" class="info-content">
        <labelled-info label="Produtor">
          {{ termData.unidade_produtiva.produtor }}
        </labelled-info>
      </v-col>
      <v-col cols="12" md="3" class="info-content">
        <labelled-info label="Unidade Produtiva">
          {{ termData.unidade_produtiva.nome }}
        </labelled-info>
      </v-col>
    </v-row>
    <h2 class="my-5 primary--text word-break text-responsible">
      TERMO DE ADESÃO AO PROGRAMA ALGODÃO BRASILEIRO RESPONSÁVEL – ABR -  COM OPÇÃO
      DE ADESÃO AO LICENCIAMENTO DE COMERCIALIZAÇÃO DE <i>BETTER COTTON</i> – BCI –
      SAFRA {{  termData.safra_virgente }}
    </h2>

    <h3 class="secondary-text--text word-break text-responsible">
      NOMEAÇÃO DE REPRESENTANTE E DECLARAÇÃO ÉTICA DE COMPROMISSO COM A PRÁTICA
      DA SUSTENTABILIDADE
    </h3>

    <p class="ma-0 my-5 primary--text word-break text-responsible">
      <strong>Anexo II do Regulamento</strong>
    </p>

    <div class="d-flex fill cards-content">
      <v-card class="ml-2 flex-1" color="grey lighten-3">
        <v-card-title class="primary-text--text word-break title-content">
          Dados da Unidade Produtiva
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-row class="mt-3">
                <v-col cols="12" class="card-infos">
                  <labelled-info cols="12" label="Unidade Produtiva">
                    {{ termData.unidade_produtiva.nome || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12"  class="card-infos">
                  <labelled-info cols="12" label="Estado UF">
                    {{ termData.unidade_produtiva.uf || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12"  class="card-infos">
                  <labelled-info label="E-mail">
                    {{ termData.unidade_produtiva.email || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>

              <v-row class="mt-3">
                <v-col cols="12"  class="card-infos">
                  <labelled-info cols="12" label="Associação Estadual">
                    {{
                      termData.unidade_produtiva.associacao_estadual.sigla ||
                      "Não informado"
                    }}
                  </labelled-info>
                </v-col>
              </v-row>
            </v-col>

            <v-col cols="12" md="6">
              <v-row class="mt-3">
                <v-col cols="12"  class="card-infos">
                  <labelled-info cols="12" label="Produtor / Grupo">
                    {{ termData.unidade_produtiva.produtor || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12"  class="card-infos">
                  <labelled-info cols="12" label="Município">
                    {{ termData.unidade_produtiva.municipio || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
              <v-row class="mt-3">
                <v-col cols="12" class="card-infos">
                  <labelled-info  cols="12" label="Telefone (DDD/Nº)">
                    {{ termData.unidade_produtiva.telefone || "Não informado" }}
                  </labelled-info>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="card-infos">
              <labelled-info label="Associação Estadual" v-if="termData.unidade_produtiva.associacao_estadual &&
                termData.unidade_produtiva.associacao_estadual.length
                ">
                {{
                  `${termData.unidade_produtiva.associacao_estadual.razao_social} -
                                ${termData.unidade_produtiva.associacao_estadual.sigla}`
                }}
              </labelled-info>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="ml-2 flex-1" color="grey lighten-3">
        <v-card-title class="primary-text--text word-break text-responsible">
          Dados do Responsável pela Unidade Produtiva
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" class="card-infos">
              <labelled-info label="Nome">
                {{ termData.unidade_produtiva.responsavel.nome }}
              </labelled-info>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" class="card-infos">
              <labelled-info cols="12" label="E-mail">
                {{ termData.unidade_produtiva.responsavel.email }}
              </labelled-info>
            </v-col>
          </v-row>
          <v-row class="mt-3">
            <v-col cols="12" class="card-infos">
              <labelled-info cols="12" label="Telefone (DDD/Nº)">
                {{ termData.unidade_produtiva.responsavel.telefone }}
              </labelled-info>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
    <div class="word-break term-text-content">
      <p class="mt-5 word-break text-responsible">
        Por meio do presente termo, a unidade produtiva/grupo/produtor acima
        identificada solicita sua adesão ao
        <strong>Programa Algodão Brasileiro Responsável – ABR e ao Programa Standard
          Brasil HVI (SBRHVI)</strong>, este último por prazo indeterminado, autorizando a equipe técnica da
        Associação Estadual e os auditores da certificadora contratada,
        devidamente credenciados e identificados, a proceder à verificação de
        campo em sua unidade produtiva e no escritório responsável pela
        documentação dos recursos humanos e constatar através da
        <strong>(1) lista de Verificação para Diagnóstico da Propriedade (VDP) e (2)
          lista de Verificação para Certificação da Propriedade (VCP) para a safra
          {{  termData.safra_virgente }},</strong>
        o cumprimento dos princípios legais constantes do Regulamento do Programa
        ABR, visando à obtenção do Certificado Algodão Brasileiro Responsável.
      </p>

      <p class="mt-5 word-break text-responsible">
        A unidade produtiva/grupo/produtor autoriza também o uso pela Associação
        Estadual de imagens digitalizadas das conformidades e adequações
        executadas na unidade produtiva.
      </p>

      <p class="word-break text-responsible">
        A unidade produtiva/grupo/produtor, por meio de seu representante legal signatário e qualificado, declara
        espontaneamente e, sob pena de ser excluído do programa, que tem conhecimento dos princípios éticos e legais
        que integram o regulamento de concessão do Certificado. Declara, também, que sua produção agropecuária
        não utiliza, nem utilizará mão de obra infantil nem qualquer forma de trabalho forçado ou análogo à escravidão,
        degradante ou indigno.
      </p>

      <p class="word-break text-responsible">
        A unidade produtiva/grupo/produtor reconhece, ainda, que o processo de certificação do Programa ABR será
        celebrado por ela ou pela Associação Estadual diretamente com uma empresa certificadora credenciada pela
        Abrapa.
      </p>

      <p class="word-break text-responsible">
        A unidade produtiva terá até o dia 30 de junho de cada ano/safra para fazer a adesão ao programa. 
      </p>

      <p class="text-responsible">
        <strong class="word-break ">Toda unidade produtiva certificada ABR pode receber, também, por livre opção, o
          licenciamento para
          comercialização de créditos da <i>BETTER COTTON</i>.</strong>
      </p>

      <h2 class="mt-5 mb-5 primary--text word-break text-responsible">
        Licenciamento <i>Better Cotton</i> (BCI) e Compromissos do Produtor
      </h2>

      <h3 class="mb-2 text-responsible">
        <span class="latter mr-2 word-break ">A.</span> Devida Diligência de Grupos Agrícolas com mais de 20.000 hectares de área plantada de algodão
      </h3>
      <p class="word-break text-responsible">
        A partir da safra 2024/2025, produtores ou Grupos Agrícolas que administram áreas plantadas com algodão
        maiores que 20.000 hectares, seja em uma única propriedade ou na soma de áreas de todas as fazendas do
        grupo, passarão por um processo de Devida Diligência (DD) para serem elegíveis para vender a produção de
        algodão como <i>Better Cotton</i>. Grupos Agrícolas aprovados devem repetir o processo a cada 3 anos.
      </p>
      <p class="word-break text-responsible">
        O objetivo da Devida Diligência é reduzir e gerenciar potenciais impactos sociais e ambientais associados às
        atividades do Grupo Agrícola, além do escopo da certificação em nível de fazenda. O processo de Devida
        Diligência visa identificar, prevenir, relatar e mitigar esses riscos.
      </p>
      <p class="word-break text-responsible">
        O processo de Devida Diligência começará após a inscrição no ABR, juntamente com o Termo de Adesão ao
        <i>Better Cotton</i>, e tornará pré-requisito para os grupos que optarem pela licença <i>Better Cotton</i>. 
      </p>
      <p class="text-responsible">
        O processo será gerenciado pela Better Cotton, com o apoio de consultores independentes e cobrirá as
        seguintes áreas:

        <ul class="word-break text-start">
          <li>Ética;</li>
          <li>Governança;</li>
          <li>Recursos humanos;</li>
          <li>Governança e estabilidade financeira;</li>
          <li>Meio ambiente e operações (inclui uso da terra).</li>
        </ul>
      </p>
      <p class="word-break text-responsible">
        O processo é estruturado da seguinte forma:
      </p>
      <v-simple-table class="my-5">
        <thead>
          <tr>
            <th class="column-table w-40">Atividade</th>
            <th class="column-table w-40">Descrição</th>
            <th class="column-table">Meses de 2025</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="column-table mr-2">
              <strong>
                1. Criação de um grupo de trabalho para orientar a
                preparação da implementação do arcabouço de Devida
                Diligência
              </strong>
            </td>
            <td class="column-table">
              O grupo de trabalho será composto por representantes da <i>Better
              Cotton</i>, da Abrapa e de outras partes interessadas relevantes
              acordadas mutuamente.
            </td>
            <td class="column-table">Fevereiro</td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                2. Revisão e adequação do arcabouço ao contexto brasileiro
              </strong>
            </td>
            <td class="column-table">
              <p class="word-break ">
                A revisão buscará:
              </p>
              <ul class="word-break">
                <li>
                  Limitar a estrutura aos aspectos essenciais das operações dos Grupos Agrícolas;
                </li>
                <li>
                  Identificar potenciais sobreposições e sinergias com outros
                  processos de Devida Diligência (desde que Grupos Agrícolas
                  concordem em divulgar relatórios de outros processos semelhantes);
                </li>
                <li>Simplificar os padrões de conduta empresarial reconhecidos
                  internacionalmente no contexto brasileiro, garantindo que o
                  processo apoie a adoção do Better Cotton brasileiro por varejistas globais.
                </li>
              </ul>
            </td>
            <td class="column-table">
              Fevereiro/Março
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                3. Identificação de Grupos Agrícolas para implementação piloto do procedimento
              </strong>
            </td>
            <td class="column-table">
              A Abrapa e seus associados recomendarão, pelo menos, três
              grupos para passar pelo procedimento revisado e compartilhar
              lições aprendidas para a versão consolidada do arcabouço.
              Observação: Relatórios individuais dos grupos permanecerão
              confidenciais. Espera-se apenas que lições aprendidas do
              processo sejam compartilhadas para incorporá-las à versão final.
            </td>
            <td class="column-table">
              Fevereiro/Março
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                4. Contratação de consultoria independente para dar suporte à 
                implementação piloto do procedimento de Devida Diligência
              </strong>
            </td>
            <td class="column-table">
              A <i>Better Cotton</i> contratará especialistas no Brasil para analisar a
              documentação de suporte e elaborar os relatórios de Devida
              Diligência.
            </td>
            <td class="column-table">
              Fevereiro/Março
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                5. Implementação piloto do procedimento de Devida Diligência
              </strong>
            </td>
            <td class="column-table">
              A implementação piloto será composta pela apresentação de evidências, 
              análise por um especialista independente e elaboração de um Plano de Ação 
              Corretiva – se relevante.
            </td>
            <td class="column-table"> 
              Abril/Maio
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                6. Consolidação de um arcabouço de Devida Diligência adequado
              </strong>
            </td>
            <td class="column-table">
              Após a revisão e implementação dos pilotos, o grupo de trabalho consolidará uma versão final do arcabouço.
            </td>
            <td class="column-table">
              Junho/Julho
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>
                7. Divulgação dos resultados piloto e do arcabouço revisado
              </strong>
            </td>
            <td class="column-table">
              Sessões informativas e material de apoio serão disseminados a 
              todas as partes interessadas envolvidas e elegíveis para o
              processo de Devida Diligência.
            </td>
            <td class="column-table">
              Agosto
            </td>
          </tr>
          <tr>
            <td class="column-table mr-2">
              <strong>     
                8. Implementação oficial do arcabouço e seu procedimento
              </strong>
            </td>
            <td class="column-table">
              Os Grupos Agrícolas que atendem aos critérios da <i>Better Cotton</i> 
              serão convidados a passar pelo procedimento de Devida Diligência.
            </td>
            <td class="column-table">
              Setembro/Dezembro
            </td>
          </tr>
        </tbody>
      </v-simple-table>
      <p class="word-break text-responsible">
        <strong>
          Para serem elegíveis para vender sua produção de algodão da safra 2024/2025 como <i>Better Cotton</i> na
          plataforma <i>Better Cotton</i>, todos os Grupos Agrícolas com mais de 20.000 hectares de algodão devem ter
          respondido todas as perguntas do questionário e enviado os documentos associados ao processo de
          Devida Diligência até o dia 31 de outubro de 2025.
        </strong>
      </p>
      <p class="word-break text-responsible">
        Nos casos em que for aplicável, o Grupo Agrícola deverá implementar o Plano de Ação Corretivo de Devida
        Diligência, dentro do prazo estabelecido em acordo mútuo. Caso o Grupo Agrícola não consiga sanar, em tempo
        hábil, qualquer não conformidade identificada, ou caso seja detectado, durante o processo de Devida Diligência,
        algum risco ou não conformidade inaceitável, à <i>Better Cotton</i> reserva-se o direito de suspender ou cancelar
        imediatamente a elegibilidade do Grupo Agrícola para comercializar algodão <i>Better Cotton</i> (incluindo o acesso individual à Plataforma <i>Better Cotton</i>), estendendo essa medida a todas as fazendas sob sua propriedade, operação, controle ou afiliação, conforme necessário. Nesse caso, transações também podem ser canceladas.
      </p>
     
      <h3 class="mb-2 text-responsible">
        <span class="latter mr-2 word-break ">B.</span> Redução do uso de defensivos agrícolas
      </h3>
      <p class="word-break text-responsible">
        Abrapa e <i>Better Cotton</i> irão trabalhar em conjunto na busca de alternativas para reduzir o uso, além de tomar medidas para eliminação gradual dos seguintes ingredientes ativos nas lavouras de algodão, devido aos seus
        possíveis efeitos nocivos à saúde humana e ao meio ambiente. Compromisso de descontinuação até 2027:
        Abamectina, Arsênio e Seus Compostos, Bifentrina, Carbendazim, Ciproconazol, Clorpirifós, Clorotalonil,
        Dibrometo De Diquat, Epoxiconazol, Glufosinato De Amônio, Hidróxido De Fentina, Lambda-Cialotrina,
        Mancozebe, Metomil, Propiconazol e Tiacloprida.
      </p>
      <p class="word-break text-responsible">
        Os itens de CMP do protocolo ABR foram ajustados na VDP/VCP, no item 8.20 e 8.21, para cumprir os requisitos
        de licenciamento da <i>Better Cotton</i>, e proíbem o uso de pesticidas listados em: 
      </p>
      <ul class="word-break">
        <li>Anexos A e B da Convenção de Estocolmo;</li>
        <li>Anexos do Protocolo de Montreal;</li>
        <li>Anexo III da Convenção de Rotterdam &sup1; e Pesticidas altamente perigosos, incluídos na lista do Sistema
          Globalmente Harmonizado de Classificação e Rotulagem de Produtos Químicos, toxidade aguda
          categoria 1 ou 2 e classificação da OMS 1 e 2b <strong>(Fenpropatrina, Azociclotina e Beta Ciflutrina)</strong>;
        </li>
        <li>Produtores que desejam obter a licença <i>Better Cotton</i> devem se comprometer a reduzir em, pelo menos, 5%, a quantidade de pesticidas (kg) por hectare. A meta a ser alcançada será avaliada comparando os resultados de 2027-28 com os de 2022-23.</li>
      </ul>

      <h3 class="mt-5 mb-2 word-break text-responsible">
        <span class="latter mr-2 word-break">C.</span> Requisitos da Cadeia de Custódia
        <i>Better Cotton</i>
      </h3>
      <p class="word-break text-responsible">
        Para aderir ao licenciamento da <i>Better Cotton</i>, o produtor deve revisar e concordar com as Normas da Cadeia
        de Custódia (CoC) <i>Better Cotton</i>. As Normas foram adaptadas para produtores certificados pelo ABR e podem
        ser acessadas através da lista de requisitos (em português):
        <a href="https://abrapa-my.sharepoint.com/personal/sustentabilidade_abrapa_abrapa_com_br/_layouts/15/onedrive.aspx?id=%2Fpersonal%2Fsustentabilidade%5Fabrapa%5Fabrapa%5Fcom%5Fbr%2FDocuments%2FBCI%2FSafra%2024%5F25%2FCadeia%20de%20Custodia%2FRequisitos%20CoC%20Better%20Cotton%20para%20Produtores%20ABR%20%28safra%202024%2D25%29%2Epdf&parent=%2Fpersonal%2Fsustentabilidade%5Fabrapa%5Fabrapa%5Fcom%5Fbr%2FDocuments%2FBCI%2FSafra%2024%5F25%2FCadeia%20de%20Custodia&ga=1"
          target="_blank"> Requisitos CoC Better Cotton para Produtores ABR(safra 2024-25).pdf</a>.
      </p>
      <p class="word-break text-responsible">
        Para comercializar o algodão BCI para a safra 2024/25, deve-se preencher o Acordo de Cadeia de Custódia (CoC) para Large Farms até 25 de julho de 2025, via
        <a href="http://bit.ly/AcordoCoC-BCI" target="_blank"> bit.ly/AcordoCoC-BCI</a>. Caso o produtor complete o Acordo <i>Better Cotton</i> de Cadeia de Custódia (CoC) para Large Farms e, após 30 de setembro de 2025, não for aprovado para certificação do Programa ABR, o Acordo <i>Better Cotton</i> será automaticamente anulado.
      </p>
      <p class="word-break text-responsible">
        No caso de um grupo possuir várias fazendas, apenas 1 (um) Acordo de CoC precisa ser preenchido. O Acordo de CoC inclui a Política Antissuborno, os Termos e Condições da Plataforma BCP e o Protocolo para Produtores que Subcontratam Beneficiamento.
        <br>
        Para ler o texto integral das Normas da CoC (em inglês), acesse: 
        <br>
        <a href="https://bettercotton.org/wp-content/uploads/2023/05/Better-Cotton-Chain-of-Custody-Standard-v.1.0.pdf"
          target="_blank"> https://bettercotton.org/wp-content/uploads/2023/05/Better-Cotton-Chain-of-Custody-Standard-v.1.0.pdf</a>.
      </p>

      <h3 class="mb-2 word-break text-responsible">
        <span class="latter mr-2 word-break">D.</span> Relatório de Indicadores de Resultados
      </h3>
      <p class="text-responsible">
        A unidade produtiva optante pelo licenciamento BCI compromete-se a colaborar, efetuando a entrega à
        Associação Estadual de todas as informações para a elaboração do Relatório de Indicadores de Resultados, incluindo:
        <ul class="word-break text-start">
          <li>Área (ha);</li>
          <li>Produção (kg de algodão em pluma);</li>
          <li>Produtividade (kg / ha de algodão em pluma);</li>
          <li>Uso de fertilizantes (litros ou quilos por hectare);</li>
          <li>Uso de defensivos (quilos ou litros de produto comercial por hectare);</li>
          <li>Uso da água - irrigação (m³);</li>
          <li>Treinamentos ofertados e número de participantes.</li>
          <li>Uso de combustível na cultura do algodão.*</li>
        </ul>
      </p>

      <p class="mt-5 word-break text-responsible"><strong>Atenção:</strong></p>

      <p class="word-break text-responsible">
        As informações serão obrigatoriamente enviadas à <i>Better Cotton</i> Initiative e estarão protegidas pela cláusula de confidencialidade, e somente serão divulgados dados compilados nacionalmente. Nenhum produtor/grupo jamais terá seus dados divulgados individualmente. 
      </p>
      <p class="word-break text-responsible">
        *A partir da safra 24/25 será obrigatório o reporte da quantidade e tipo de combustível (gasolina, diesel, querosene, etc.) utilizado na produção do algodão (cultivo, irrigação e aplicação de pesticidas) e/ou transporte. Deve-se reportar o uso desde a preparação do solo para o plantio até a colheita.
        <br>
        A informação será utilizada para contribuir para o cálculo do GHG (Gases de Efeito Estufa).
      </p>
      <p class="word-break text-responsible">
        &sup1; A Convenção de Rotterdam sobre Consentimento Prévio Informado (PIC) regulamenta a troca de informações no comércio internacional sobre determinados pesticidas perigosos (princípios ativos e formulações). <strong> Alguns dos princípios ativos listados ou propostos para a lista são usados no algodão no Brasil. A Abrapa avisará se/quando um produto químico for adicionado ao Anexo III e fornecerá orientações adicionais aos produtores.
        </strong>
      </p>

      <h2 class="mt-15 mb-5 primary--text word-break text-responsible">
        ANEXO DO TERMO DE ADESÃO AO PROGRAMA ALGODÃO BRASILEIRO RESPONSÁVEL – ABR – COM OPÇÃO DE ADESÃO AO LICENCIAMENTO
        DE COMERCIALIZAÇÃO DE <i>BETTER COTTON</i> – SAFRA {{  termData.safra_virgente }}
      </h2>

      <h2 class="mb-4 word-break text-responsible">Minimizando os impactos prejudiciais das práticas de uso de defensivos</h2>

      <p  class="text-responsible">A Abrapa está de acordo com a intenção da <i>Better Cotton</i> Standard de minimizar os impactos prejudiciais das
        práticas de uso de defensivos na saúde humana e no meio ambiente de forma mais ampla. Como defensora
        dos produtores de algodão brasileiros e como Parceira Estratégica da <i>Better Cotton</i>, a Abrapa está
        empenhada em avaliar o uso de pesticidas altamente perigosos (HHPs) na produção de algodão brasileira
        para alcançar esse importante objetivo.</p>

      <p class="text-responsible">O Protocolo ABR continuará a promover a adoção de abordagens de manejo integrado de pragas que
        reduzam a dependência de pesticidas e minimizem os potenciais efeitos nocivos dos pesticidas,
        principalmente na saúde humana.</p>

      <h2 class="mb-4 word-break text-responsible">Desenvolvimento de Estratégia Abrapa</h2>

      <p class="text-responsible">Por meio de consultas e engajamento com parceiros do setor e partes interessadas, a Abrapa avaliará os
        riscos dos HHPs (pesticidas altamente perigosos), identificará as melhores práticas de mitigação de risco e
        possíveis controles alternativos com o objetivo de reduzir a toxicidade total dos pesticidas aplicados no
        algodão, no decorrer do tempo. A Abrapa se dedica a este esforço importante para definir um plano sólido
        com prazo determinado para atingir tal objetivo. Os produtores serão incluídos no desenvolvimento da
        estratégia e serão notificados sobre seu desenvolvimento e/ou quaisquer alterações na classificação de um
        produto químico e/ou alterações previstas para os requisitos de licenciamento ABR - <i>Better Cotton</i>.</p>

      <h2 class="mb-4 word-break text-responsible">Classificações dos HHPs (pesticidas altamente perigosos)</h2>

      <p class="text-responsible">A toxicidade aguda refere-se às substâncias que representam um risco para a saúde humana quando há
        exposições únicas ou múltiplas (oral, dérmica ou inalatória) num curto período de tempo (geralmente menos
        de 24 horas). A substância pode causar efeitos nocivos ou letais que são evidentes imediatamente ou logo
        após a exposição.</p>

      <p class="text-responsible">A classificação da Organização Mundial da Saúde (OMS) é baseada na toxicidade aguda, e o Sistema
        Globalmente Harmonizado de Classificação e Rotulagem de Produtos Químicos (GHS) tem categorias de
        perigo de toxicidade aguda correspondentes. Os pesticidas mais agudamente perigosos são classificados
        como:</p>
      <ul class="text-start">
        <li>OMS Classe Ia: Extremamente perigoso | OMS Classe Ib: altamente perigoso</li>
        <li>GHS Categoria 1 | GHS Categoria 2</li>
      </ul>

      <p class="mt-2 word-break text-responsible">Toxicidade crônica refere-se a uma substância que pode causar efeitos prejudiciais por um
        período
        prolongado, geralmente após exposição repetida ou contínua, às vezes permanecendo por toda a vida do
        organismo exposto. Os efeitos podem ser descobertos muitos anos após a exposição e podem incluir danos
        graves, irreversíveis ou mesmo letais. Além da toxicidade aguda, o GHS classifica as substâncias com base
        em riscos crônicos para a saúde e riscos ambientais. Os riscos crônicos à saúde são listados em três
        categorias: carcinogenicidade, mutagenicidade e toxicidade reprodutiva, e são chamados coletivamente de
        substâncias CMR.</p>

      <ul class="text-start">
        <li><strong class="word-break text-responsible">Carcinogenicidade:</strong> Um carcinógeno é uma substância ou mistura que induz
          câncer ou aumenta sua
          incidência. O GHS categoriza o potencial carcinogênico como conhecido (Categoria 1A) ou presumido
          (Categoria 1B) como tendo potencial carcinogênico para humanos.</li>
        <li><strong class="word-break text-responsible">Mutagenicidade:</strong> Um mutagênico é uma substância ou mistura que pode causar
          mutações nas
          células
          germinativas dos humanos que podem ser transmitidas à sua progenitura . As substâncias da categoria 1A do
          GHS são conhecidas por induzir mutações hereditárias em células germinativas de humanos, e a categoria 1B
          do GHS deve ser considerada como se induzissem mutações hereditárias em células germinativas de
          humanos. </li>
        <li><strong class="word-break text-responsible">Toxidade reprodutiva:</strong> Um repro-tóxico é uma substância ou mistura com
          efeitos adversos na
          função sexual
          e fertilidade em machos e fêmeas adultos, bem como toxicidade que afeta o desenvolvimento dos seus
          descendentes. O GHS classifica a toxicidade reprodutiva como tóxicos reprodutivos humanos conhecidos
          (Categoria 1A) ou presumidos (Categoria 1B).</li>
      </ul>
      <v-card class="mt-8 mb-2" color="grey lighten-3">
        <v-card-title class="word-break text-responsible">
          A ADESÃO AO PROCESSO DE LICENCIAMENTO BCI DEVE SER FEITA POR MEIO DESTE
          TERMO DE ADESÃO, SENDO OBRIGATÓRIA A SINALIZAÇÃO DE UMA DAS OPÇÕES A
          SEGUIR:</v-card-title>
        <v-card-text>
          <p class="primary-text--text text-responsible">
            <strong class="word-break ">Adesão ao Licenciamento BCI?</strong>
          </p>
          <v-radio-group v-model="state.acceptBci" class="info-content">
            <v-radio label="Sim" :value="'yes'"></v-radio>
            <v-radio label="Não" :value="'no'"></v-radio>
          </v-radio-group>

          <!-- <p class="text-responsible">
            {{ $t("terms.bci_warning") }}
          </p> -->
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>
<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },
  components: {
    LabelledInfo
  },
  data: () => ({
    acceptBCI: false,
    termData: {
      safra_virgente: null,
      aceite_bci: undefined,
      unidade_produtiva: {
        nome: null,
        municipio: null,
        uf: null,
        email: null,
        produtor: null,
        telefone: null,
        celular: null,
        associacao_estadual: {},
        responsavel: {
          id: null,
          nome: null,
          email: null,
          cpf: null,
          telefone: null,
          celular: null
        }
      }
    }
  }),
  async mounted() {
    await this.fetchTerm();
  },
  computed: {
    ...mapGetters({
      inviteDetail: "invited/getInviteDetail"
    })
  },
  methods: {
    ...mapActions("invite", ["fetchAbrTerm"]),
    ...mapActions(["toggleSnackbar"]),

    async fetchTerm() {
      try {
        this.termData = await this.fetchAbrTerm(this.inviteDetail);
        console.log(this.termData.safra_virgente);
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.latter {
  font-size: 1.4rem;
}

.word-break {
  word-break: break-word;
}

.column-table {
  text-align: left;
}

.w-40 {
  width: 40%;
}

@media screen and (max-width: 960px) {
  .cards-content {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    .title-content {
      justify-content: center;
    }

    .card-infos {
      display: flex;
      justify-content: center;
      text-align: center;
    }
  }

  .column-table {
    text-align: center !important;
  }

  .term-text-content {
    gap: 12px;
  }

  .info-content {
    display: flex;
    justify-content: center;
    text-align: center;

    ::v-deep .v-input--radio-group__input {
          align-items: center;
    }
  }
  
}
</style>
